<template>
  <main class="no-main">
    <div class="ps-breadcrumb">
      <div class="container-fluid-sy">
        <ul class="ps-breadcrumb__list">
          <li class="active">
            <router-link :to="{ name: 'home' }">Home</router-link>
          </li>
          <li class="active"><a href="#"> Myaccount</a></li>
          <li><a href="javascript:void(0);"> Orders View</a></li>
        </ul>
      </div>
    </div>
    <section class="section--shopping-cart">
      <div class="container shopping-container">
        <div class="card mt-4 print">
          <div class="card-header p-4">
            <a
              href="#/"
              class="p-logo d-none d-print-inline-block router-link-active"
              ><img
                src="http://suyee-family.loveoolove.com/img/logo.07cadfde.png"
                alt="Logo"
                class="logo"
            /></a>
            <router-link
              :to="{ name: 'user-orders-history' }"
              class="btn btn-outline-dark btn-lg d-print-none mr-2"
            >
              &lt; Back
            </router-link>
            <button
              onclick="window.print()"
              class="btn btn-custom btn-lg d-print-none"
            >
              Print
            </button>
            <div class="float-right">
              <h3 class="mb-0">Order #{{orderID}}</h3>
              Date: {{ date }}
            </div>
          </div>
          <template v-if="!isLoaded">
            <div class="card-body">
              <div class="row mb-4">
                <div class="col-sm-6">
                  <h5 class="mb-3">From:</h5>
                  <h3 class="text-dark mb-1"><Skeletor /></h3>
                  <div><Skeletor /></div>
                  <div><Skeletor /></div>
                  <div><Skeletor /></div>
                  <div><Skeletor /></div>
                  <div>Email: <Skeletor /></div>
                  <div>Phone: <Skeletor /></div>
                </div>
                <div class="col-sm-6">
                  <h5 class="mb-3">To:</h5>
                  <h3 class="text-dark mb-1"><Skeletor /></h3>
                  <div><Skeletor /></div>
                  <div><Skeletor /></div>
                  <div><Skeletor /></div>
                  <div><Skeletor /></div>
                  <div>Email: <Skeletor /></div>
                  <div>Phone: <Skeletor /></div>
                </div>
              </div>
              <div class="table-responsive-sm">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th class="text-center">No</th>
                      <th>Product SKU</th>
                      <th>Description</th>
                      <th class="text-right">Price</th>
                      <th class="text-center">Qty</th>
                      <th class="text-right">Total</th>
                    </tr>
                  </thead>
                  <tbody v-if="order.items">
                    <tr v-for="(i, index) in 3" :key="index">
                      <td class="text-center"><Skeletor /></td>
                      <td class="text-left font-weight-bold"><Skeletor /></td>
                      <td class="text-left"><Skeletor /></td>
                      <td class="text-right"><Skeletor /></td>
                      <td class="text-center"><Skeletor /></td>
                      <td class="text-right"><Skeletor /></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row">
                <div class="col-lg-4 col-sm-5"></div>
                <div class="col-lg-4 col-sm-5 ml-auto">
                  <table class="table table-clear">
                    <tbody>
                      <tr>
                        <td class="text-left">
                          <strong class="text-dark">Subtotal</strong>
                        </td>
                        <td class="text-right"><Skeletor /></td>
                      </tr>
                      <tr>
                        <td class="text-left">
                          <strong class="text-dark">Shipping Amount</strong>
                        </td>
                        <td class="text-right"><Skeletor /></td>
                      </tr>
                      <tr>
                        <td class="text-left">
                          <strong class="text-dark">Discount Amount</strong>
                        </td>
                        <td class="text-right"><Skeletor /></td>
                      </tr>
                      <tr>
                        <td class="text-left">
                          <strong class="text-dark">Total</strong>
                        </td>
                        <td class="text-right">
                          <strong class="text-dark"><Skeletor /></strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </template>

          <template v-if="isLoaded">
            <div class="card-body">
              <div class="row mb-4">
                <div class="col-sm-6">
                  <h5 class="mb-3">From:</h5>
                  <h3 class="text-dark mb-1">
                    {{ order.billing_address.first_name }}
                    {{ order.billing_address.last_name }}
                  </h3>
                  <div>
                    {{ order.billing_address.address1[0] }},
                    {{ order.billing_address.address1[1] }}
                  </div>
                  <div>
                    {{ order.billing_address.city }},
                    {{ order.shipping_address.postcode }}
                  </div>
                  <div>{{ order.billing_address.state }}</div>
                  <div>{{ order.billing_address.country_name }}</div>
                  <div>Email: {{ order.billing_address.email }}</div>
                  <div>Phone: {{ order.billing_address.phone }}</div>
                </div>
                <div class="col-sm-6">
                  <h5 class="mb-3">To:</h5>
                  <h3 class="text-dark mb-1">
                    {{ order.shipping_address.first_name }}
                    {{ order.shipping_address.last_name }}
                  </h3>
                  <div>
                    {{ order.billing_address.address1[0] }},
                    {{ order.billing_address.address1[1] }}
                  </div>
                  <div>
                    {{ order.shipping_address.city }},
                    {{ order.shipping_address.postcode }}
                  </div>
                  <div>{{ order.shipping_address.state }}</div>
                  <div>{{ order.shipping_address.country_name }}</div>
                  <div>Email: {{ order.shipping_address.email }}</div>
                  <div>Phone: {{ order.shipping_address.phone }}</div>
                </div>
              </div>
              <div class="table-responsive-sm">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th class="text-center">ID</th>
                      <th>Product SKU</th>
                      <th>Description</th>
                      <th class="text-right">Price</th>
                      <th class="text-center">Qty</th>
                      <th class="text-right">Total</th>
                    </tr>
                  </thead>
                  <tbody v-if="order.items">
                    <tr
                      v-for="(item, index) in order.items"
                      :key="index"
                      :number="increment()"
                    >                    
                      <td class="text-center">{{ item.id }}</td>
                      <td class="text-left font-weight-bold">{{ item.sku }}</td>
                      <td class="text-left">{{ item.product.name }}</td>
                      <td class="text-right">
                        {{ item.product.formated_price }}
                      </td>
                      <td class="text-center">{{ item.qty_ordered }}</td>
                      <td class="text-right">{{ item.formated_total }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row">
                <div class="col-lg-4 col-sm-5"></div>
                <div class="col-lg-4 col-sm-5 ml-auto">
                  <table class="table table-clear">
                    <tbody>
                      <tr>
                        <td class="text-left">
                          <strong class="text-dark">Subtotal</strong>
                        </td>
                        <td class="text-right">
                          {{ order.formated_sub_total }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-left">
                          <strong class="text-dark">Shipping Amount</strong>
                        </td>
                        <td class="text-right">
                          {{ order.formated_shipping_amount }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-left">
                          <strong class="text-dark">Discount Amount</strong>
                        </td>
                        <td class="text-right">
                          {{ order.formated_base_discount_amount }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-left">
                          <strong class="text-dark">Total</strong>
                        </td>
                        <td class="text-right">
                          <strong class="text-dark">{{
                            order.formated_grand_total
                          }}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import "vue-skeletor/dist/vue-skeletor.css";
import { Skeletor } from "vue-skeletor";

export default {
  data() {
    return {
      isLoaded: false,
      date: new Date().toJSON().slice(0, 10).replace(/-/g, "-"),
      number: 0,
      orderID:"",
    };
  },

  components: { Skeletor },

  mounted() {
    if (this.$route.params) {
      if (this.$route.params.id) {
        this.fetchOrder(this.$route.params.id).then((cb) => {
          if (cb) {
            this.isLoaded = true;
          }
        });
      this.orderID = this.$route.params.id;
      }
    }
  },

  computed: {
    ...mapGetters("orders", ["order", "isLoading"]),
  },

  methods: {
    ...mapActions("orders", ["fetchOrder"]),

    increment() {
      this.number = this.number + 1;
      return this.number;
    },
  },
};
</script>
<style lang="scss" scoped>
div {
  text-transform: capitalize;
}
.p-logo {
  width: 130px;
  margin-top: -50px;
}
.btn-custom {
  background-color: #ff7200;
  color: white;
}
.btn-custom:hover {
  background-color: #f06f06;
}
@media print{
  .no-main {
    padding-top: 5px;
  }
}
</style>